#welcome {
    max-width: 950px;
    text-align: center;
    margin: auto;

    p {
        color: #888;
        line-height: 2.2rem;
        font-size: 1.15rem;
        font-weight: 300;
    }
}
