header {
    width: 100vw;
    z-index:1020;
    background:#fff;
}

#top{
    padding:10px 0;
}

@include media-breakpoint-down(xl) {
    #logo{
        img{
            max-width:260px;
        }
    }
}

@include media-breakpoint-down(md) {
    header {
        height: 60px;

        .container {
            max-width: 100%;
        }
    }

    #top {
        border: none;
        padding:5px 0;
    }

    #logo {
        position:relative;
        z-index:300;
        img {
            width: 220px;
        }
    }
}
