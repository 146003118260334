#menu {
    text-align: right;

    .list-inline, .list-inline-item {
        margin: 0px;

        > a {
            display: block;
            padding: 32px 15px 5px 15px;
            color: #111;
            position: relative;
            font-size: 1rem;
            font-weight: 700;
            transition: all 0.4s ease;
            float: left;

            &:hover {
                text-decoration: none;
                color: $primary;
            }
        }

        &.has-submenu {
            > a {
                padding: 12px 5px 5px 15px;
            }

            .submenu {
                color: #111;

                a {
                    display: block;
                    padding: 10px 20px;
                }
            }
        }
    }

    i {
        float: left;
        padding: 16px 15px 5px 5px;
        cursor: pointer;
        color: #111;
        transition: all 0.4s ease;
    }
}

#nav-toggle {
    position: absolute;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 20;
    top: 20px;
    right: 50px;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #111;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .3s all;

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0px;
        }

        &:nth-child(1), &:nth-child(2) {
            top: 0px;
        }

        &:nth-child(3), &:nth-child(4) {
            top: 9px;
        }

        &:nth-child(5), &:nth-child(6) {
            top: 18px;
        }
    }

    &.invert {
        span {
            background: $body-color;
        }
    }

    &.open {
        span {
            &:nth-child(1), &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2), &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 0px;
                top: 2px;
            }

            &:nth-child(2) {
                left: 10px;
                top: 2px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 0px;
                top: 12px;
            }

            &:nth-child(6) {
                left: 10px;
                top: 12px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .fixed {
        #menu {
            a {
                color: $body-color;
                transition: all 0.4s ease;
            }

            i {
                color: $body-color;
                transition: all 0.4s ease;
            }

            .list-inline-item {
                &.has-submenu {

                    .submenu {
                        box-shadow: 0px 2px 5px 0px rgba(0,0,0,.13);
                    }
                }
            }
        }
    }

    #menu {
        .list-inline-item {
            &.has-submenu {
                position: relative;

                .submenu {
                    position: absolute;
                    top: 70px;
                    right: 0;
                    background: #fff;
                    text-align: left;
                    width: 150%;
                    padding: 10px 0;

                    a {
                        color: $body-color;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    #menu {
        .list-inline, .list-inline-item {
            > a {
                padding: 12px 10px 5px 10px;
              
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #menu {
        background: rgba(255,255,255,0.95);
        position: fixed;
        top: 0px;
        right: -100vw;
        width: 100%;
        height: 100vh;
        z-index: 10;
        transition: all 0.4s ease;

        .list-inline {
            width: 100%;
            margin: auto;
            position: absolute;
            top: 80px;
            bottom: 50px;
            left: 0px;
            right: 0px;
            overflow-y: scroll;
        }

        .list-inline-item {
            text-align: center;
            display: block;
            clear: both;
            text-align: center;

            > a {
                font-size: 1.5rem;
                padding: 15px 0px;
                float: none;
                display: inline-block;
            }

            i {
                float: none;
                font-size: 1.4rem;
                padding: 15px 15px 15px 20px;
            }

            &.has-submenu {
                > a {
                    padding: 15px 5px 15px 35px;
                }

                .submenu {
                    margin-bottom: 30px;
                    font-weight:bold;
                    font-size:1.2rem;

                    a {
                        color: #111;
                        display: inline-block;
                        text-transform: initial;
                    }
                }
            }
        }

        &.open {
            right: 0px;
            transition: all 0.4s ease;
        }
    }

    #nav-toggle {
        top: 15px;
        right: 20px;
    }
}
