#hero {
    position: relative;
    width: 100%;
    z-index: 0;
    max-height: 100vh;
    overflow: hidden;
    min-height:800px;

    .bg-secondary:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 500px;
        background: $secondary;
        transform: skew(7deg);
        transform-origin: top;
        z-index: 1;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .content {
        position:relative;
        z-index: 2;
        font-size: 1.25rem;

        h1 {
            font-size: 4rem;
            line-height: 1;
        }
    }
    &.textpage{
        min-height:initial;
    }
}

@include media-breakpoint-down(md) {
    #hero {
        max-height:inherit;
        .content {
            font-size: 1rem;

            h1 {
                font-size: 2rem;
                letter-spacing: .1rem;
            }
        }

        .overlay{
            position:relative;
        }

        .bg-secondary:before {
            top: -50px;
            left: -140px;
            height: 90px;
            width: 150vw;
            transform: rotate(-5deg);
        }
    }
}
