$font-family-base: 'PT Sans', sans-serif;
$headings-font-family: 'PT Sans', sans-serif;

$primary: #134434;
$secondary: #4b655d;

$body-color: #1c2129;

$h1-font-size: 3rem;
$h2-font-size: 2.6rem;

$headings-font-weight: 400;

$lead-font-size:1.5rem;

$link-color: $secondary;

$input-box-shadow: none;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1400px, xxl: 1600px );

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1400px );

@import "../../node_modules/bootstrap/scss/variables";
