.section {
    padding: 50px 0px;

    &:nth-child(odd) {
        background: #e8e9e9;
    }
}

@include media-breakpoint-down(md) {
    .section {
        padding: 100px 0px;
    }
}

@include media-breakpoint-down(sm) {
    .section {
        padding: 50px 0px;
    }
}
