a {
    color: $primary;
}
h1, h2, h3, h4{
    font-weight:bold;
}

figcaption {
    font-size: 0.875rem;
    color: #777;
}

h3, .h3 {
    font-size: 1.6rem;
}

@include media-breakpoint-down(md) {
    h1, .h1 {
        font-size: 2.75rem;
    }

    h2, .h2 {
        font-size: 2rem;
    }
    h3, .h3{
        font-size:1.3rem;
    }
}
