/* Umbraco Forms */
.umbraco-forms-form {
    .umbraco-forms-fieldset {
        > legend {
            padding-bottom: 10px;
        }

        .umbraco-forms-page {
            margin-bottom: 15px;
        }

        .umbraco-forms-field {

            .umbraco-forms-field-wrapper {

                .form-check {
                    .form-check-label {
                        cursor: pointer;
                    }
                }

                .field-validation-error {
                    color: $danger;
                    display: inline-block;
                }
            }

            textarea {
                height: 200px;
            }
        }
    }
}
