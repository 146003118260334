footer {
    background: #fff;

    .footer-content {
        padding-top: 20px;
        padding-bottom: 50px;

        .fab {
            font-size: 2rem;
            color: #1877f2;
        }
    }

    #copyright {
        background: #f3f3f3;
        color: #ccc;
        text-align: center;
        padding: 10px 0px;
        font-size: 0.75rem;

        a {
            color: #a5a5a5;
        }
    }
}

@include media-breakpoint-down(md) {
    footer{
        .footer-content{
            padding:30px 15px;
        }
    }
}
